import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/";

export async function deleteConnection(token, id) {
  return axios.delete(baseUrl + "connections/" + id, {
    headers: { authorization: `Bearer ${token}` },
  });
}

export async function listEmails(token) {
  return axios
    .get(baseUrl + "emails", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function listMeetings(token) {
  return axios
    .get(baseUrl + "meetings", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function getMeetingDetails(token, id) {
  return axios
    .get(baseUrl + "meetings/" + id, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function listConnections(token) {
  return axios
    .get(baseUrl + "connections", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function login() {
  return axios.get(baseUrl + "sso/login").then(({ data }) => data);
}

export async function loginWithGoogle(token) {
  return axios
    .get(baseUrl + "oauth/gmail", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function loginWithDrive(token) {
  return axios
    .get(baseUrl + "oauth/drive", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function loginWithCalendar(token) {
  return axios
    .get(baseUrl + "oauth/calendar", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function loginWithAnalytics(token) {
  return axios
    .get(baseUrl + "oauth/analytics", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function loginWithZendesk(token) {
  return axios
    .get(baseUrl + "oauth/zendesk", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function logout(token) {
  return axios
    .post(baseUrl + "sso/logout", null, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function search(token, prompt, connections, threadId) {
  return axios
    .post(
      baseUrl + "chat",
      { prompt, connections, thread_id: threadId },
      { headers: { authorization: `Bearer ${token}` } },
    )
    .then(({ data }) => data);
}

export async function fetchAnalyticsProperties(token) {
  return axios
    .get(baseUrl + "analytics/properties", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);
}

export async function updateAnalyticsProperties(token, properties) {
  return axios
    .post(
      baseUrl + "analytics/property",
      { properties: properties },
      { headers: { authorization: `Bearer ${token}` } },
    )
    .then(({ data }) => data);
}
