import PropTypes from "prop-types";
import Logo from "../images/logo.png";

function Header({ isLoggedIn, isLoggingOut, onLogout }) {
  return (
    <div className="d-flex align-items-center gap-3">
      <img alt="Pivotal" className="rounded" height={75} src={Logo} />
      <div className="d-flex flex-column align-items-start">
        <h1 className="mb-0">Pivotal</h1>
        <p className="small mb-0">
          <a href="https://getpivotal.ai/" rel="noreferrer" target="_blank">
            https://getpivotal.ai/
          </a>
          {isLoggedIn && (
            <>
              <span className="mx-1">&bull;</span>
              {isLoggingOut && <span className="text-muted">Logging out…</span>}
              {!isLoggingOut && (
                // eslint-disable-next-line
                <a href="" onClick={onLogout}>
                  Logout
                </a>
              )}
            </>
          )}
        </p>
      </div>
    </div>
  );
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isLoggingOut: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default Header;
