import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSearchParam, useSessionStorage } from "react-use";
import DashboardPage from "./components/DashboardPage";
import LoginPage from "./components/LoginPage";
import SettingsPage from "./components/SettingsPage";
import { Provider } from "./contexts/AuthContext";
import PropertiesModal from "./components/PropertiesModal";

function App() {
  const [knownToken, setKnownToken] = useSessionStorage("token");
  const token = useSearchParam("token");
  const logout = useSearchParam("logout");
  const [isSettingsVisible, setSettingsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const modalParam = params.get("analytics");

    if (modalParam === "1") {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    window.history.pushState({}, "", window.location.pathname);
    setShowModal(false);
  };

  const handleAnalyticsModalOpen = () => {
    setShowModal(true);
  };

  // handle login from backend
  useEffect(() => {
    if (token) {
      setKnownToken(token);
      window.history.pushState({}, "", window.location.pathname);
    }
  }, [token, setKnownToken]);

  // handle logout from backend
  useEffect(() => {
    if (logout) {
      setKnownToken(null);
      window.history.pushState({}, "", window.location.pathname);
    }
  }, [logout, setKnownToken]);

  return (
    <Container className="my-auto py-3">
      <Row className="justify-content-center">
        <Col lg={!!knownToken ? 12 : 6}>
          {!!knownToken && (
            <Provider value={knownToken}>
              {isSettingsVisible ? (
                <SettingsPage
                  onSettingsClose={() => setSettingsVisible(false)}
                  onAnalyticsModalOpen={handleAnalyticsModalOpen}
                />
              ) : (
                <DashboardPage
                  onSettingsClick={() => setSettingsVisible(true)}
                />
              )}
              <PropertiesModal
                show={showModal}
                handleClose={handleCloseModal}
              />
            </Provider>
          )}
          {!knownToken && <LoginPage />}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
