const units = ["year", "month", "week", "day", "hour", "minute", "second"];

export const timeAgo = (dateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";

  const formatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  return formatter.format(Math.trunc(diff.as(unit)), unit);
};
