import classNames from "classnames";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ChatMessageLink from "./ChatMessageLink";
import RobotIcon from "../images/icon-robot.png";

function ChatMessage({ message }) {
  return (
    <div className="d-flex flex-column w-100 gap-1">
      {!!message.isMine && <small className="text-muted ms-auto">You</small>}
      {!message.isMine && (
        <small className="d-flex align-items-center gap-1 text-muted">
          <img alt="Pivotal" className="img-inline" src={RobotIcon} />
          <span>Pivotal says</span>
        </small>
      )}
      <div
        className={classNames([
          "d-flex",
          "flex-column",
          { "ms-5": !!message.isMine, "me-5": !message.isMine },
        ])}
      >
        <div
          className={classNames([
            "chat-message",
            "p-3",
            {
              "chat-message--from-me": !!message.isMine,
              "chat-message--from-them": !message.isMine,
            },
            { "ms-auto": !!message.isMine, "me-auto": !message.isMine },
          ])}
        >
          <ReactMarkdown>{message.content}</ReactMarkdown>
        </div>
      </div>
      {message.sources.length > 0 && (
        <div className="d-flex gap-1">
          {message.sources
            .filter((x) => !!x.url)
            .map((source) => (
              <ChatMessageLink {...source} />
            ))}
        </div>
      )}
    </div>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ChatMessage;
