import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import EmailsCard from "./EmailsCard";
import MeetingsCard from "./MeetingsCard";
import SearchCard from "./SearchCard";

function DashboardPage({ onSettingsClick }) {
  return (
    <Row style={{ height: "75vh" }}>
      <Col className="d-flex flex-column h-100" lg={7} xl={8}>
        <SearchCard onSettingsClick={onSettingsClick} />
      </Col>
      <Col className="d-flex flex-column h-100 gap-3" lg={5} xl={4}>
        <div className="w-100 position-relative flex-fill">
          <div className="position-absolute w-100 h-100">
            <MeetingsCard />
          </div>
        </div>
        <div className="w-100 position-relative flex-fill">
          <div className="position-absolute w-100 h-100">
            <EmailsCard />
          </div>
        </div>
      </Col>
    </Row>
  );
}

DashboardPage.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
};

export default DashboardPage;
