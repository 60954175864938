import classNames from "classnames";
import {
  faCheck,
  faCircleNotch,
  faCog,
  faSearch,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, FormControl } from "react-bootstrap";
import FilterIcon from "../images/icon-filter.png";
import {
  connectionLogos,
  connectionNames,
  findConnection,
} from "../utilities/connections";

function SearchForm({
  clearNonce,
  connections,
  followUpQuestions,
  isInitial,
  isSearching,
  onSearch,
  onSettingsClick,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [connectionsFilter, setConnectionsFilter] = useState([]);
  const [enabledConnections, setEnabledConnections] = useState([]);

  useEffect(() => {
    setEnabledConnections(
      Object.keys(connectionNames).filter((x) => {
        if (x === "gmail") {
          return !!findConnection(connections, "imap", "gmail");
        }

        return !!findConnection(connections, x);
      }),
    );
  }, [connections]);

  const onSearchInputChanged = (e) => {
    setSearchInput(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    onSearch(searchInput, connectionsFilter);
  };

  const onConnectionToggled = (connection) => {
    let updated;
    if (connectionsFilter.includes(connection)) {
      updated = connectionsFilter.filter((x) => x !== connection);
    } else {
      updated = [...connectionsFilter, connection];
    }

    setConnectionsFilter(updated);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (clearNonce) {
      setSearchInput("");
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  }, [clearNonce, inputRef]);

  function onSettingsClicked(e) {
    e.preventDefault();
    onSettingsClick();
  }

  return (
    <div className="d-flex w-100 flex-column gap-3">
      {!isInitial && followUpQuestions?.length && (
        <div className="follow-up-questions">
          <div className="btn-toolbar gap-1">
            {followUpQuestions.map((x, i) => (
              <Button
                key={`follow-up-question-${i}`}
                onClick={() => onSearch(x, connectionsFilter)}
                size="sm"
                variant="light"
              >
                {x}
              </Button>
            ))}
          </div>
        </div>
      )}
      {isInitial ? (
        <Form className="w-100 d-flex gap-3" onSubmit={onSearchSubmit}>
          <FormControl
            className="p-3"
            defaultValue={searchInput}
            name="q"
            onChange={onSearchInputChanged}
            placeholder="Search for anything…"
            ref={inputRef}
            size="lg"
          />
          <Button
            className={classNames([
              { "d-none": isSearching || searchInput.length <= 0 },
            ])}
            disabled={isSearching || searchInput.length <= 0}
            size="lg"
            type="submit"
            variant={isSearching ? "outline-primary" : "primary"}
          >
            <FontAwesomeIcon
              className="mx-1"
              fixedWidth
              icon={isSearching ? faCircleNotch : faSearch}
              spin={isSearching}
            />
          </Button>
        </Form>
      ) : (
        <Form className="w-100 d-flex gap-3" onSubmit={onSearchSubmit}>
          <FormControl
            className="p-3 w-100"
            defaultValue={searchInput}
            name="q"
            onChange={onSearchInputChanged}
            placeholder="Ask a follow-up question…"
            ref={inputRef}
            size="lg"
          />
          <Button
            className={classNames([
              { "d-none": isSearching || searchInput.length <= 0 },
            ])}
            disabled={isSearching || searchInput.length <= 0}
            size="lg"
            type="submit"
            variant={isSearching ? "outline-primary" : "primary"}
          >
            <FontAwesomeIcon
              className="mx-1"
              fixedWidth
              icon={isSearching ? faCircleNotch : faPaperPlane}
              spin={isSearching}
            />
          </Button>
        </Form>
      )}
      <div className="d-flex justify-content-between">
        <Dropdown>
          <Dropdown.Toggle
            className="d-flex align-items-center justify-content-center"
            id="dropdown-filters"
            size="sm"
            variant="light"
          >
            <img
              alt="Filter Icon"
              className="img-inline me-1"
              src={FilterIcon}
            />
            {connectionsFilter.length > 1 && (
              <span>
                {connectionNames[connectionsFilter[0]]} &amp;{" "}
                {connectionsFilter.length - 1} more
              </span>
            )}
            {connectionsFilter.length === 1 && (
              <span>{connectionNames[connectionsFilter[0]]}</span>
            )}
            {connectionsFilter.length < 1 && <span>All sources</span>}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {enabledConnections.map((x) => (
              <Dropdown.Item
                key={`filter-connection-${x}`}
                className={classNames([
                  "d-flex",
                  "align-items-center",
                  { active: connectionsFilter.includes(x) },
                ])}
                onClick={() => onConnectionToggled(x)}
              >
                <img
                  alt={connectionNames[x]}
                  className="img-inline me-2"
                  src={connectionLogos[x]}
                />{" "}
                <span>{connectionNames[x]}</span>
                {connectionsFilter.includes(x) && (
                  <FontAwesomeIcon className="ms-1" icon={faCheck} />
                )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          className="d-flex align-items-center justify-content-center"
          onClick={onSettingsClicked}
          size="sm"
          variant="light"
        >
          <FontAwesomeIcon className="me-1" icon={faCog} /> Settings
        </Button>
      </div>
    </div>
  );
}

SearchForm.propTypes = {
  clearNonce: PropTypes.number,
  connections: PropTypes.array,
  followUpQuestions: PropTypes.array,
  isInitial: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
};

export default SearchForm;
