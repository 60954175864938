import { faArrowRight, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, ButtonToolbar, Card, CardBody } from "react-bootstrap";
import Header from "./Header";
import { login } from "../utilities/api";

function LoginPage() {
  const [isLoggingIn, setLoggingIn] = useState(false);

  const onLoginClicked = () => {
    setLoggingIn(true);
    login()
      .then(({ url }) => {
        window.location.href = url;
      })
      .finally(() => setLoggingIn(false));
  };

  return (
    <Card className="border-0 shadow">
      <CardBody>
        <div className="d-flex flex-column gap-3">
          <Header isLoggedIn={false} isLoggingOut={false} onLogout={() => {}} />
          <p className="lead mb-0">
            You need to be logged in with your organization account to use this
            service. Click on the below button to continue with single sign-on.
          </p>
          <ButtonToolbar>
            <Button
              className="p-3 px-5"
              disabled={isLoggingIn}
              onClick={onLoginClicked}
              size="lg"
              variant="primary"
            >
              Login
              <FontAwesomeIcon
                className="ms-2"
                icon={isLoggingIn ? faCircleNotch : faArrowRight}
                spin={isLoggingIn}
              />
            </Button>
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  );
}

export default LoginPage;
