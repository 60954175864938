import classNames from "classnames";
import {
  faChevronLeft,
  faCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
} from "react-bootstrap";
import AuthContext from "../contexts/AuthContext";
import {
  deleteConnection,
  listConnections,
  loginWithGoogle,
  loginWithDrive,
  loginWithCalendar,
  loginWithAnalytics,
  loginWithZendesk,
} from "../utilities/api";
import {
  connectionLogos,
  connectionNames,
  findConnection,
} from "../utilities/connections";

function SettingsPage({ onSettingsClose, onAnalyticsModalOpen }) {
  const token = useContext(AuthContext);
  const [connections, setConnections] = useState([]);
  const [toBeDisconnected, setToBeDisconnected] = useState(null);
  const [isDisconnecting, setDisconnecting] = useState(false);
  const [confirmDisconnect, setConfirmDisconnect] = useState(false);

  function refreshConnections() {
    listConnections(token).then(({ connections }) => {
      setConnections(connections);
    });
  }

  useEffect(refreshConnections, [token, setConnections]);

  function onGoogleLoginClicked(e) {
    e.preventDefault();
    loginWithGoogle(token).then(({ url }) => (window.location.href = url));
  }

  function onDriveLoginClicked(e) {
    e.preventDefault();
    loginWithDrive(token).then(({ url }) => (window.location.href = url));
  }

  function onCalendarLoginClicked(e) {
    e.preventDefault();
    loginWithCalendar(token).then(({ url }) => (window.location.href = url));
  }

  function onAnalyticsLoginClicked(e) {
    e.preventDefault();
    loginWithAnalytics(token).then(({ url }) => (window.location.href = url));
  }

  function onZendeskLoginClicked(e) {
    e.preventDefault();
    loginWithZendesk(token).then(({ url }) => (window.location.href = url));
  }

  function onSettingsCloseClicked(e) {
    e.preventDefault();
    onSettingsClose();
  }

  function hideDisconnectConfirmation(e) {
    e.preventDefault();
    setConfirmDisconnect(false);
  }

  function showDisconnectConfirmation(e, id) {
    e.preventDefault();
    setToBeDisconnected(id);
    setConfirmDisconnect(true);
  }

  function onDisconnectClicked(e) {
    e.preventDefault();
    setDisconnecting(true);
    deleteConnection(token, toBeDisconnected).finally(() => {
      setDisconnecting(false);
      setConfirmDisconnect(false);
      refreshConnections();
    });
  }

  function showAnalyticsSettings(e) {
    e.preventDefault();
    onAnalyticsModalOpen();
  }

  const bitbucket = findConnection(connections, "bitbucket");
  const confluence = findConnection(connections, "confluence");
  const drive = findConnection(connections, "google", "drive");
  const calendar = findConnection(connections, "google", "calendar");
  const analytics = findConnection(connections, "google", "analytics");
  const github = findConnection(connections, "github");
  const gmail = findConnection(connections, "imap", "gmail");
  const hubspot = findConnection(connections, "hubspot");
  const jira = findConnection(connections, "jira");
  const notion = findConnection(connections, "notion");
  const zendesk = findConnection(connections, "zendesk", "knowledge_base");

  return (
    <>
      {confirmDisconnect && (
        <Modal
          backdrop="static"
          centered
          keyboard={false}
          onHide={hideDisconnectConfirmation}
          show={confirmDisconnect}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">
              Connected account along with all indexed information will be
              removed from system.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={hideDisconnectConfirmation}>
              Close
            </Button>
            <Button
              disabled={isDisconnecting}
              onClick={onDisconnectClicked}
              variant="danger"
            >
              {isDisconnecting ? "Disconnecting" : "Disconnect"}{" "}
              <FontAwesomeIcon className="ms-1" icon={faTrash} />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Card className="border-0 shadow">
        <CardBody>
          <small>
            <a
              className="text-decoration-none"
              href=""
              onClick={onSettingsCloseClicked}
            >
              <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> Back to
              Search
            </a>
          </small>
        </CardBody>
        <ListGroup className="list-group-flush border-top">
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["bitbucket"]}
              className="img-inline me-2"
              src={connectionLogos["bitbucket"]}
            />{" "}
            <span>{connectionNames["bitbucket"]}</span>
            {!!bitbucket && (
              <small className="text-muted ms-auto">Connected</small>
            )}
            {!bitbucket && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!bitbucket, "text-danger": !bitbucket },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["confluence"]}
              className="img-inline me-2"
              src={connectionLogos["confluence"]}
            />{" "}
            <span>{connectionNames["confluence"]}</span>
            {!!confluence && (
              <small className="text-muted ms-auto">Connected</small>
            )}
            {!confluence && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!confluence, "text-danger": !confluence },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["drive"]}
              className="img-inline me-2"
              src={connectionLogos["drive"]}
            />{" "}
            <span>{connectionNames["drive"]}</span>
            {!!drive && (
              <small className="text-muted ms-auto">
                Connected as: {drive.metadata.email} &bull;{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={(e) => showDisconnectConfirmation(e, drive.id)}
                >
                  Disconnect?
                </a>
              </small>
            )}
            {!drive && (
              <small className="ms-auto">
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={onDriveLoginClicked}
                >
                  Connect
                </a>
              </small>
            )}{" "}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!drive, "text-danger": !drive },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["calendar"]}
              className="img-inline me-2"
              src={connectionLogos["calendar"]}
            />{" "}
            <span>{connectionNames["calendar"]}</span>
            {!!calendar && (
              <small className="text-muted ms-auto">
                Connected as: {calendar.metadata.email} &bull;{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={(e) => showDisconnectConfirmation(e, calendar.id)}
                >
                  Disconnect?
                </a>
              </small>
            )}
            {!calendar && (
              <small className="ms-auto">
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={onCalendarLoginClicked}
                >
                  Connect
                </a>
              </small>
            )}{" "}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!calendar, "text-danger": !calendar },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["analytics"]}
              className="img-inline me-2"
              src={connectionLogos["analytics"]}
            />{" "}
            <span>{connectionNames["analytics"]}</span>
            {!!analytics && (
              <small className="text-muted ms-auto">
                Connected as: {analytics.metadata.email} &bull;{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={showAnalyticsSettings}
                >
                  Settings
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={(e) => showDisconnectConfirmation(e, analytics.id)}
                >
                  Disconnect?
                </a>
              </small>
            )}
            {!analytics && (
              <small className="ms-auto">
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={onAnalyticsLoginClicked}
                >
                  Connect
                </a>
              </small>
            )}{" "}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!analytics, "text-danger": !analytics },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["github"]}
              className="img-inline me-2"
              src={connectionLogos["github"]}
            />{" "}
            <span>{connectionNames["github"]}</span>
            {!!github && (
              <small className="text-muted ms-auto">Connected</small>
            )}
            {!github && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!github, "text-danger": !github },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["gmail"]}
              className="img-inline me-2"
              src={connectionLogos["gmail"]}
            />{" "}
            <span>{connectionNames["gmail"]}</span>
            {!!gmail && (
              <small className="text-muted ms-auto">
                Connected as: {gmail.metadata.email} &bull;{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={(e) => showDisconnectConfirmation(e, gmail.id)}
                >
                  Disconnect?
                </a>
              </small>
            )}
            {!gmail && (
              <small className="ms-auto">
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={onGoogleLoginClicked}
                >
                  Connect
                </a>
              </small>
            )}{" "}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!gmail, "text-danger": !gmail },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["hubspot"]}
              className="img-inline me-2"
              src={connectionLogos["hubspot"]}
            />{" "}
            <span>{connectionNames["hubspot"]}</span>
            {!!hubspot && (
              <small className="text-muted ms-auto">Connected</small>
            )}
            {!hubspot && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!hubspot, "text-danger": !hubspot },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["jira"]}
              className="img-inline me-2"
              src={connectionLogos["jira"]}
            />{" "}
            <span>{connectionNames["jira"]}</span>
            {!!jira && <small className="text-muted ms-auto">Connected</small>}
            {!jira && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!jira, "text-danger": !jira },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["notion"]}
              className="img-inline me-2"
              src={connectionLogos["notion"]}
            />{" "}
            <span>{connectionNames["notion"]}</span>
            {!!notion && (
              <small className="text-muted ms-auto">Connected</small>
            )}
            {!notion && (
              <small className="text-muted ms-auto">Disconnected</small>
            )}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!notion, "text-danger": !notion },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
          <ListGroupItem className="d-flex align-items-center">
            <img
              alt={connectionNames["zendesk"]}
              className="img-inline me-2"
              src={connectionLogos["zendesk"]}
            />{" "}
            <span>{connectionNames["zendesk"]}</span>
            {!!zendesk && (
              <small className="text-muted ms-auto">
                Connected as: {zendesk.metadata.email} &bull;{" "}
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={(e) => showDisconnectConfirmation(e, zendesk.id)}
                >
                  Disconnect?
                </a>
              </small>
            )}
            {!zendesk && (
              <small className="ms-auto">
                <a
                  className="text-decoration-none"
                  href=""
                  onClick={onZendeskLoginClicked}
                >
                  Connect
                </a>
              </small>
            )}{" "}
            <FontAwesomeIcon
              className={classNames([
                { "text-success": !!zendesk, "text-danger": !zendesk },
                "ms-2",
              ])}
              icon={faCircle}
            />
          </ListGroupItem>
        </ListGroup>
      </Card>
    </>
  );
}

SettingsPage.propTypes = {
  onSettingsClose: PropTypes.func.isRequired,
  onAnalyticsModalOpen: PropTypes.func.isRequired,
};

export default SettingsPage;
