import { PropTypes } from "prop-types";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import ChatMessage from "./ChatMessage";
import Header from "./Header";
import SearchForm from "./SearchForm";
import AuthContext from "../contexts/AuthContext";
import RobotIconAnimated from "../images/icon-robot-animated.gif";
import { listConnections, logout as logoutUrl, search } from "../utilities/api";

function SearchCard({ onSettingsClick }) {
  const token = useContext(AuthContext);
  const [connections, setConnections] = useState([]);
  const [isLoggingOut, setLoggingOut] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [clearNonce, setClearNonce] = useState(null);

  useEffect(() => {
    listConnections(token).then(({ connections }) => {
      setConnections(connections);
    });
  }, [token, setConnections]);

  function onLogOutClicked(e) {
    e.preventDefault();
    setLoggingOut(true);
    logoutUrl(token)
      .then(({ url }) => {
        window.location.href = url;
      })
      .finally(() => setLoggingOut(false));
  }

  function onResetClicked(e) {
    e.preventDefault();
    setThreadId(null);
    setMessages([]);
    setClearNonce("");
  }

  const onSearchSubmit = useCallback(
    (q, connections) => {
      if (!q) {
        return;
      }

      let msgs = [...messages, { content: q, sources: [], isMine: true }];
      setClearNonce(Date.now());
      setMessages(msgs);
      setSearching(true);
      search(token, q, connections, threadId)
        .then((message) => {
          const urls = (message.sources || []).map((x) => x.url);
          const sources = (message.sources || []).filter(
            (x, i, array) => urls.indexOf(x.url) === i,
          );
          setMessages([...msgs, { ...message, sources }]);
          if (!threadId || threadId !== message.thread_id) {
            setThreadId(message.thread_id);
          }
        })
        .finally(() => setSearching(false));
    },
    [setClearNonce, messages, setMessages, threadId, setThreadId, token],
  );

  const chatHistory = useRef(null);

  useEffect(() => {
    if (chatHistory.current) {
      chatHistory.current.scrollTop = chatHistory.current.scrollHeight;
    }
  }, [isSearching, messages, chatHistory]);

  return (
    <Card className="border-0 shadow w-100 h-100">
      <CardBody className="w-100 h-100 position-relative">
        {!!threadId && (
          <small
            className="position-absolute"
            style={{ top: "10px", right: "10px" }}
          >
            <a href="" onClick={onResetClicked}>
              Start over?
            </a>
          </small>
        )}
        <div className="d-flex flex-column w-100 h-100 gap-3">
          <Header
            isLoggedIn={true}
            isLoggingOut={isLoggingOut}
            onLogout={onLogOutClicked}
          />
          <div
            className="d-flex flex-column flex-fill w-100 gap-1 overflow-auto"
            ref={chatHistory}
          >
            {messages.length > 0 && (
              <div className="w-100 d-flex flex-column gap-1">
                {messages.map((x, i) => (
                  <ChatMessage key={`message-${i}`} message={x} />
                ))}
              </div>
            )}
            {isSearching && (
              <div className="d-flex flex-column w-100 justify-content-center gap-1 py-3">
                <img
                  alt="Pivotal"
                  className="mx-auto"
                  height={25}
                  src={RobotIconAnimated}
                />
                <p className="text-center text-muted">Hang tight for a bit!</p>
              </div>
            )}
          </div>
          <SearchForm
            clearNonce={clearNonce}
            connections={connections}
            followUpQuestions={messages[0]?.follow_up_questions}
            isInitial={!threadId}
            isSearching={isSearching}
            onReset={onResetClicked}
            onSearch={onSearchSubmit}
            onSettingsClick={onSettingsClick}
          />
        </div>
      </CardBody>
    </Card>
  );
}

SearchCard.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
};

export default SearchCard;
