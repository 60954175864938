import BitBucketLogo from "../images/logo-bitbucket.svg";
import CalendarLogo from "../images/logo-calendar.svg";
import AnalyticsLogo from "../images/logo-analytics.svg";
import ConfluenceLogo from "../images/logo-confluence.svg";
import DriveLogo from "../images/logo-drive.svg";
import GithubLogo from "../images/logo-github.svg";
import GmailLogo from "../images/logo-gmail.svg";
import HubspotLogo from "../images/logo-hubspot.png";
import JiraLogo from "../images/logo-jira.svg";
import NotionLogo from "../images/logo-notion.svg";
import ZendeskLogo from "../images/logo-zendesk.svg";

export const connectionLogos = {
  bitbucket: BitBucketLogo,
  calendar: CalendarLogo,
  analytics: AnalyticsLogo,
  confluence: ConfluenceLogo,
  drive: DriveLogo,
  gmail: GmailLogo,
  github: GithubLogo,
  hubspot: HubspotLogo,
  jira: JiraLogo,
  notion: NotionLogo,
  zendesk: ZendeskLogo,
};

export const connectionNames = {
  bitbucket: "BitBucket",
  calendar: "Calendar",
  analytics: "Analytics",
  confluence: "Confluence",
  drive: "Google Drive",
  gmail: "Gmail",
  github: "Github",
  hubspot: "Hubspot",
  jira: "Jira",
  notion: "Notion",
  zendesk: "Zendesk",
};

export function findConnection(connections, type, subtype) {
  return connections.find((x) => {
    if (x.connection === type) {
      if (subtype) {
        return x.subtype === subtype;
      } else {
        return true;
      }
    }

    return false;
  });
}
