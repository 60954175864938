import classNames from "classnames";
import {
  faArrowRight,
  faPaperclip,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Spinner,
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import AuthContext from "../contexts/AuthContext";
import { listMeetings, getMeetingDetails } from "../utilities/api";
import { connectionLogos, connectionNames } from "../utilities/connections";
import { timeAgo } from "../utilities/datetime";

function MeetingsCard() {
  const token = useContext(AuthContext);
  const [meetings, setMeetings] = useState([]);
  const [meetingContext, setMeetingContext] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    listMeetings(token)
      .then(({ meetings }) => {
        setMeetings(meetings);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const closeDetails = () => setDetailsOpen(false);
  const showDetails = ({ meeting, context }) => {
    setMeetingContext(context);
    setMeetingDetails(meeting);
    setDetailsOpen(true);
  };

  return (
    <Card className="border-0 shadow w-100 h-100">
      {detailsOpen && (
        <Modal
          backdrop="static"
          centered
          keyboard={false}
          onHide={closeDetails}
          show={detailsOpen}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="h4 mb-1">{meetingDetails.title}</p>
            <p className="text-muted">
              {timeAgo(DateTime.fromISO(meetingDetails.start))} &bull;{" "}
              {DateTime.fromISO(meetingDetails.start).toLocaleString({
                ...DateTime.DATE_SHORT,
                weekday: "long",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            {meetingDetails.attendees?.length > 1 && (
              <p>
                <FontAwesomeIcon className="me-1" icon={faUserFriends} />{" "}
                {meetingDetails.attendees.length} People (
                {meetingDetails.attendees[0].name ||
                  meetingDetails.attendees[0].email}{" "}
                + {meetingDetails.attendees.length - 1})
              </p>
            )}
            {meetingDetails.attachments?.length > 0 && (
              <ButtonToolbar className="mb-3">
                {meetingDetails.attachments.map((x) => (
                  <Button
                    key={x.id}
                    size="sm"
                    variant="outline-dark"
                    as="a"
                    href={x.link}
                    target="_blank"
                  >
                    <FontAwesomeIcon className="me-1" icon={faPaperclip} />{" "}
                    {x.name}
                  </Button>
                ))}
              </ButtonToolbar>
            )}
            <p className="mb-0">
              <strong>Suggestion:</strong>{" "}
              <ReactMarkdown>{meetingContext}</ReactMarkdown>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={closeDetails}>
              Close
            </Button>
            <Button
              variant="primary"
              as="a"
              href={meetingDetails.meet_link}
              target="_blank"
            >
              Join now <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <CardBody className="w-100 h-100 position-relative">
        <div className="d-flex flex-column w-100 h-100 gap-3">
          <CardTitle className="mb-0">Upcoming meetings</CardTitle>
          {loading ? (
            <div className="d-flex align-items-center w-100 h-100">
              <div className="d-flex flex-column w-100 gap-1">
                <Spinner animation="border" className="mx-auto" role="status">
                  <span className="visually-hidden">Loading&hellip;</span>
                </Spinner>
                <p className="text-muted mx-auto mb-0">
                  Checking calendar&hellip;
                </p>
              </div>
            </div>
          ) : meetings.length ? (
            <div className="d-flex flex-column flex-fill w-100 gap-1 overflow-auto">
              {meetings.map((x) => (
                <MeetingItem key={x.id} meeting={x} onClick={showDetails} />
              ))}
            </div>
          ) : (
            <div className="d-flex align-items-center w-100 h-100">
              <div className="d-flex flex-column w-100 gap-1">
                <p className="text-muted mx-auto mb-0">No meetings found.</p>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

MeetingsCard.propTypes = {};

function MeetingItem({ meeting, onClick }) {
  const token = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  function handleClick() {
    setLoading(true);
    getMeetingDetails(token, meeting.id)
      .then(({ meeting, context }) => {
        onClick({ meeting, context });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div
      className={classNames(
        { "meeting-item": !loading },
        "border",
        "rounded",
        "p-2",
      )}
      onClick={handleClick}
    >
      {loading && <Spinner className="float-end" size="sm" />}
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-row gap-1">
          <img
            alt={connectionNames["calendar"]}
            className={classNames("img-inline", { "img-grayscale": loading })}
            src={connectionLogos["calendar"]}
          />
          <strong className="small">Calendar</strong>
          {!loading && (
            <span className="small text-muted ms-auto">
              {timeAgo(DateTime.fromISO(meeting.start))}
            </span>
          )}
        </div>
        <p className="strong small mb-0 text-truncate">{meeting.title}</p>
        {loading ? (
          <p className="small mb-0 text-truncate">
            Loading meeting details&hellip;
          </p>
        ) : (
          <p className="small mb-0 text-truncate">
            {meeting.summary || "No description available."}
          </p>
        )}
      </div>
    </div>
  );
}

MeetingItem.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default MeetingsCard;
