import emails from "email-addresses";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Spinner } from "react-bootstrap";
import AuthContext from "../contexts/AuthContext";
import { listEmails } from "../utilities/api";
import { connectionLogos, connectionNames } from "../utilities/connections";
import { timeAgo } from "../utilities/datetime";

function EmailsCard() {
  const token = useContext(AuthContext);
  const [summary, setSummary] = useState(null);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    listEmails(token)
      .then(({ emails, summary }) => {
        setSummary(summary);
        setEmails(emails);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Card className="border-0 shadow w-100 h-100">
      <CardBody className="w-100 h-100 position-relative">
        <div className="d-flex flex-column w-100 h-100 gap-1">
          <CardTitle className="mb-0">Important emails</CardTitle>
          {loading ? (
            <div className="d-flex align-items-center w-100 h-100">
              <div className="d-flex flex-column w-100 gap-1">
                <Spinner animation="border" className="mx-auto" role="status">
                  <span className="visually-hidden">Loading&hellip;</span>
                </Spinner>
                <p className="text-muted mx-auto mb-0">
                  Checking emails&hellip;
                </p>
              </div>
            </div>
          ) : emails.length ? (
            <div className="d-flex flex-column flex-fill w-100 gap-1 overflow-auto">
              <p className="small mb-0">{summary}</p>
              {emails.map((x) => (
                <EmailItem key={x.id} email={x} />
              ))}
            </div>
          ) : (
            <div className="d-flex align-items-center w-100 h-100">
              <div className="d-flex flex-column w-100 gap-1">
                <p className="text-muted mx-auto mb-0">No emails found.</p>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

EmailsCard.propTypes = {};

function EmailItem({ email }) {
  function handleClick() {
    window.open(email.url);
  }

  return (
    <div className="email-item border rounded p-2" onClick={handleClick}>
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-row gap-1">
          <img
            alt={connectionNames["gmail"]}
            className="img-inline"
            src={connectionLogos["gmail"]}
          />
          <strong className="small">Gmail</strong>
          <span className="small text-muted ms-auto">
            {timeAgo(DateTime.fromSeconds(email.created_at))}
          </span>
        </div>
        <p className="strong small mb-0 text-truncate">{email.subject}</p>
        <p className="small mb-0 text-truncate">
          <strong>From: </strong> {emails.parseOneAddress(email.sender).address}
        </p>
      </div>
    </div>
  );
}

EmailItem.propTypes = {
  email: PropTypes.object.isRequired,
};

export default EmailsCard;
