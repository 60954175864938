import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import BitBucketLogo from "../images/logo-bitbucket.svg";
import ConfluenceLogo from "../images/logo-confluence.svg";
import DriveLogo from "../images/logo-drive.svg";
import GithubLogo from "../images/logo-github.svg";
import GmailLogo from "../images/logo-gmail.svg";
import HubspotLogo from "../images/logo-hubspot.png";
import JiraLogo from "../images/logo-jira.svg";
import NotionLogo from "../images/logo-notion.svg";
import ZendeskLogo from "../images/logo-zendesk.svg";

function ChatMessageLink({ connection, title, url }) {
  return (
    <Button
      as="a"
      className="rounded-pill d-flex align-items-center gap-1"
      href={url}
      rel="noopener noreferer"
      size="sm"
      target="_blank"
      title={title}
      variant="light"
    >
      {connection === "bitbucket" && (
        <img alt="BitBucket" className="img-inline" src={BitBucketLogo} />
      )}
      {connection === "confluence" && (
        <img alt="Confluence" className="img-inline" src={ConfluenceLogo} />
      )}
      {connection === "drive" && (
        <img alt="Drive" className="img-inline" src={DriveLogo} />
      )}
      {connection === "github" && (
        <img alt="Github" className="img-inline" src={GithubLogo} />
      )}
      {connection === "imap" && (
        <img alt="IMAP" className="img-inline" src={GmailLogo} />
      )}
      {connection === "hubspot" && (
        <img alt="Hubspot" className="img-inline" src={HubspotLogo} />
      )}
      {connection === "jira" && (
        <img alt="Jira" className="img-inline" src={JiraLogo} />
      )}
      {connection === "notion" && (
        <img alt="Notion" className="img-inline" src={NotionLogo} />
      )}
      {connection === "zendesk" && (
        <img alt="Zendesk" className="img-inline" src={ZendeskLogo} />
      )}
      <span className="link-label">{title}</span>
    </Button>
  );
}

ChatMessageLink.propTypes = {
  connection: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ChatMessageLink;
