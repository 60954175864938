import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";

import AuthContext from "../contexts/AuthContext";
import {
  fetchAnalyticsProperties,
  updateAnalyticsProperties,
  listConnections,
} from "../utilities/api";
import { findConnection } from "../utilities/connections";

function PropertiesModal({ show, handleClose }) {
  const token = useContext(AuthContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertVariant, setAlertVariant] = useState("success");
  const [connections, setConnections] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [properties, setProperties] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {
    if (isLoading || connections != null) {
      return;
    }

    setLoading(true);
    listConnections(token)
      .then(({ connections }) => {
        setConnections(connections);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [connections, isLoading, setLoading, token]);

  useEffect(() => {
    if (!show || isLoading || connections == null || properties != null) {
      return;
    }

    const analytics = findConnection(connections, "google", "analytics");
    if (!analytics) {
      return analytics;
    }

    setLoading(true);
    fetchAnalyticsProperties(token)
      .then(({ properties }) => {
        setProperties(properties);
        const selected = properties
          .filter((property) => property.selected)
          .map((property) => property.id);
        setSelectedProperties(selected);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [connections, isLoading, setLoading, show, token]);

  useEffect(() => {
    if (!alertMessage) {
      return;
    }

    const timer = setTimeout(() => {
      setAlertMessage(null);
    }, 5 * 1000);

    return () => clearTimeout(timer);
  }, [alertMessage]);

  const handleSubmit = async () => {
    const payload = selectedProperties.map((propId) => {
      const { id, name } = (properties || []).find((x) => x.id === propId);
      return { id, name };
    });

    setLoading(true);
    updateAnalyticsProperties(token, payload)
      .then((res) => {
        if (res.status === "success") {
          handleClose();
        } else {
          setAlertVariant("danger");
          setAlertMessage(res.message || "Failed to update GA4 properties.");
        }
      })
      .catch((err) => {
        setAlertVariant("danger");
        setAlertMessage("Failed to update GA4 properties.");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (propId) => {
    setSelectedProperties((previous) =>
      previous.includes(propId)
        ? previous.filter((id) => id !== propId)
        : [...previous, propId],
    );
  };

  if (!connections) {
    return null;
  }

  const analytics = findConnection(connections, "google", "analytics");
  if (!analytics) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select GA4 properties</Modal.Title>
      </Modal.Header>

      {isLoading ? (
        <Modal.Body>
          <Spinner className="float-end" size="sm" />
        </Modal.Body>
      ) : (
        <div>
          <Modal.Body>
            {alertMessage && (
              <Alert
                dismissible
                onClose={() => setAlertMessage(null)}
                variant={alertVariant}
              >
                {alertMessage}
              </Alert>
            )}
            <Form>
              {(properties || []).map((property) => (
                <Form.Check
                  key={property.id}
                  id={`ga4-property-${property.id}`}
                  type="checkbox"
                  label={property.name}
                  value={property.id}
                  onChange={() => handleSelect(property.id)}
                  checked={selectedProperties.includes(property.id)}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

export default PropertiesModal;
